import React from 'react';
import { Image } from '@alterpage/gatsby-plugin-image';

import {
    section as sectionClass,
    alternate,
    grid,
    titleText,
    subtitleText,
    contentText,
    image,
    imageRatio,
    withImage,
    noTitle,
    logoImage,
} from './article-preview-logo.module.scss';

import { ISection } from '../../models/section.model';

import Section from '../hoc/section';

interface IArticlePreviewLogoProps {
    className?: string;
    section: ISection;
    TitleTag?: React.ElementType;
}

const ArticlePreviewLogo: React.FC<IArticlePreviewLogoProps> = ({
    className = '',
    section,
    TitleTag = 'h2',
}) => {
    const {
        sectionId,
        content: { texts, media },
        settings,
        css,
        style,
    } = section;
    const alternateClass =
        settings?.repeatIndex && settings?.repeatIndex % 2 === 0 ? alternate : '';
    const [title, subtitle, description] = texts;

    const hasMedia = media.length > 0;
    const hasLogo = media.length > 1;
    const logo = hasLogo ? [media[1]] : null;

    return (
        <Section
            sectionId={sectionId}
            className={[
                sectionClass,
                alternateClass,
                hasMedia ? withImage : '',
                title ? '' : noTitle,
                className,
            ].join(' ')}
            classes={{
                container: grid,
                title: titleText,
                subtitle: subtitleText,
                description: contentText,
            }}
            css={css}
            style={style}
            title={title}
            subtitle={subtitle}
            description={description}
            TitleTag={TitleTag}
        >
            {hasLogo && logo && <Image className={logoImage} media={logo} />}
            {hasMedia && <Image className={image} ratioClass={imageRatio} media={media} />}
        </Section>
    );
};

export default ArticlePreviewLogo;
