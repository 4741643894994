import React from 'react';
import { Image } from '@alterpage/gatsby-plugin-image';

import {
    container,
    title as titleClass,
    descriptionContainer,
    description as descriptionClass,
    image,
    ratioClass,
    button,
} from './single-story.module.scss';
import { IStory } from '../../models/story.model';

import Title from '../atoms/title';
import Markdown from '../hoc/markdown';
import Button from '../atoms/button';

interface ISingleStory {
    className?: string;
    story: IStory;
}

const SingleStory: React.FC<ISingleStory> = ({ className = '', story }) => {
    return (
        <div className={`${container} ${className}`}>
            <Title Tag="h3" className={titleClass}>
                {story.title}
            </Title>
            <div className={descriptionContainer}>
                <Markdown className={descriptionClass}>{story.description}</Markdown>
                {story.buttonUrl && story.buttonText && (
                    <Button className={button} as={'link'} to={story.buttonUrl}>
                        {story.buttonText}
                    </Button>
                )}
            </div>
            <Image media={story.media} className={image} ratioClass={ratioClass} />
        </div>
    );
};

export default SingleStory;
