// extracted by mini-css-extract-plugin
export var alternate = "article-preview-logo-module--alternate--8d6b9";
export var contentText = "article-preview-logo-module--content-text--425cf";
export var grid = "article-preview-logo-module--grid--a5657";
export var image = "article-preview-logo-module--image--c31b6";
export var imageRatio = "article-preview-logo-module--image-ratio--931db";
export var logoImage = "article-preview-logo-module--logo-image--c8a1d";
export var noTitle = "article-preview-logo-module--no-title--7ad66";
export var section = "article-preview-logo-module--section--27a8c";
export var subtitleText = "article-preview-logo-module--subtitle-text--6fdc1";
export var titleText = "article-preview-logo-module--title-text--519f0";
export var withImage = "article-preview-logo-module--with-image--2fdca";